import React from 'react'
import functionIcon from '../images/function.svg'
import javaScriptIcon from '../images/codeIcon.svg'
import screen from '../images/screen.svg'
import monitor from '../images/monitor.svg'
import splitScreen from '../images/splitScreen.svg'
import notifications from '../images/notifications.svg'
import proxies from '../images/proxies.svg'
import toDo from '../images/toDo.svg'
import workspace from '../images/workspace.svg'
import Picture1 from '../images/Picture1.png'
import Picture2 from '../images/Picture2.png'
import Picture3 from '../images/Picture3.png'
import Picture4 from '../images/Picture4.png'
import Picture5 from '../images/Picture5.png'


export const featuresData = [
    {
        title:'Build your Workspaces',
        subtitle: 'BricoBrowser introduces the concept of Workspaces that allows users to split their online activities ',   
        description: (<>
            <p className="text-left">BricoBrowser introduces the concept of Workspaces that allows users to split their online activities into silos further organized into Groups and Resources
Users can create an unlimited number of groups and resources, where the resource can be any website or online service that you could open inside a Web Browser.</p>
            <p className="text-left">Users can create an unlimited number of groups and resources, where the resource can be any website or online service that you could open inside a Web Browser.</p>
            <div className="flex items-center p-2"><img src={toDo}/>
                <span className=" flex ml-2 items-center"> <span>
                    <b>Tips and Advices:</b> Follow below tips to create a workspace that will make you happier and more productive:
                </span>
                </span>
            </div>
            <ul className="list-disc text-left">
                <li>
            Consider the general area (ie work, home) and set the name
                </li>
                <li>
            Decide if you want to work with Proxies
                </li>
                <li>
            Load the website pages you need as Resources
                </li>
                <li>
            Update profiles and password in the Profile manager
                </li>
                <li>
            Upload the Resources you need
                </li>
                <li>
            Decide on the Hibernation policy for each Workspace
                </li>
            </ul>
        </>),
        appImage:'testImg',
        icon: <div className="flex items-center justify-center"><img src={workspace} className="justify-center"/> </div>,
        video: null
    },
    {
        title:'Browse with Proxies',
        subtitle: 'You can use proxies to verify a specific content online from different countries, or to bypass restrictions.',
        description: (<>
            <p className="text-left">During your normal activities you might need to use proxies, either to verify a specific content online from different countries, either to bypass restrictions. Instead of going to a website and entering the URL into a form, which is slow and tedious, you can configure a Workstation to always use a proxy and you can then just browse the web as normal.
            </p>
            <p className="text-left">BricoBrowser do not offer Proxy Services but let you use your own trusted proxy in each workspace.</p>
            <p className="text-left">Do you want to use a different proxy in each workspace for each of your identity? No problem, the traffic of each workspace will be completely isolated.</p>
            <p  className="text-left">
                <b>Step 1:</b> Go to Workspace Editor 
            </p>
            <p  className="text-left flex">
                <b>Step 2:</b> Choose the Workspace for which you want to set the proxy and press <img src={Picture1}/>
            </p>
            <p  className="text-left">
                <b>Step 3:</b> Choose “Use a specific proxy settings” and add your proxy
            </p>
            <div className="flex p-2 items-center"><img src={toDo}/>
                <span className=" flex ml-2 text-left "><span>
                    <b>Tips and Advices:</b> Please note that free proxy servers that you can find online are often slow, sometimes inject advertisement that might be not only disturbing but also contain malicious content. Choose a good quality proxy to get the best experience from using BricoBrowser.
                </span>
                </span>
            </div>
        </>),
        appImage:'testImg',
        icon: <div className="flex items-center justify-center"><img src={proxies} className="justify-center"/> </div>,
        video: null
    },
    {
        title:'Screens',
        subtitle: 'Create your own customized split visualization screen by combining up to 4 resources chosen from any of the workspaces.',
        description: (<>
            <p className="text-left">
            Create your own customized split visualization screen by combining up to 4 resources chosen from any of the workspaces.
            </p>
            <p className="text-left">
            Also each of the 4 areas can use it’s own proxy and this function is particularly useful when you need to monitor the same website using different accounts from different countries.
            </p>
            <div className="flex p-2 items-center"><img src={toDo}/>
                <span className=" flex ml-2 text-left "><span>
                    <b>Tips and Advices:</b> By allowing you to simultaneously view and work on multiple browsing sessions, BricoBrowser boosts productivity and allows you to relax while multitasking.
                </span>
                </span>
            </div>
           
        </>),
        appImage:'testImg',
        icon: <div className="flex items-center justify-center"><img src={splitScreen} className="justify-center"/> </div>,
        video: null
    },
    {
        title:'Configurable Java Scripts on page load',
        subtitle:'Following the request of some of our power users we added the possibility to inject custom JavaScript to basically  tweak any part of a website.',
        description: (<>
            <p className="text-left">
            Following the request of some of our power users we added the possibility to inject custom JavaScript to basically  tweak any part of a website.
            </p>
            <p  className="text-left">
                <b>Step 1:</b> Go to Workspace Editor / Resource Editor
            </p>
            <p  className="text-left flex">
                <b>Step 2:</b> Type in the custom JS script
            </p>
            <p  className="text-left">
                <b>Step 3:</b> Press “TEST” to check that all is working as you want 
            </p>
            <p  className="text-left">
                <b>Step 4:</b> Press “Ok” at the end to save the change
            </p>
            <div className="flex p-2 items-center"><img src={toDo}/>
                <span className=" flex ml-2 text-left ">
                    <span>
                        <b>Tips and Advices:</b> Some example of how this feature can help you:
                        <ul className="list-disc">
                            <li>Hide annoying notification bar</li>
                            <li>Change the refresh page timer</li>
                            <li>Condition buttons default functionality</li>
                        </ul>
                    </span>
                </span>
            </div>
           
        </>),
        appImage:'testImg',
        icon: <div className="flex items-center justify-center"><img src={javaScriptIcon} className="justify-center"/> </div>,
        video: null
    },
    {
        title:'Light/Dark mode',
        subtitle:'Choose the theme that suits you.',
        description: (<>
            <p className="text-left">
            In order to ensure you can work any time of the day, we created two themes: Light and Dark modes.
            </p>
            <p className="text-left">
            You can set the preferred theme by going to 
            </p>
            <span className=" flex ml-2 text-left ">
                <ul className="list-disc">
                    <li>App settings <img src={Picture2}/> </li>
                    <li>Select the mode you need:<img src={Picture3}/></li>
                </ul>
            </span>
            <p className="text-left">
            Bellow is a sample how they look:
            </p>
            <span className=" flex ml-2 text-left ">
                <ul className="">
                    <li className="font-semibold">Light theme: <img src={Picture4}/> </li>
                    <li className="font-semibold">Dark theme:<img src={Picture5}/></li>
                </ul>
            </span>
        </>),
        appImage:'testImg',
        icon: <div className="flex items-center justify-center"><img src={notifications} className="justify-center"/> </div>,
        video: null
    },
    {
        title:'Manage To Do List and Notes',
        subtitle:'A practical to-do list sets you off in the right direction and helps you focus on the tasks that are most important to you.',
        description: (<>
            <p className="text-left">
            A practical to-do list sets you off in the right direction and helps you focus on the tasks that are most important to you. 
            </p>
            <div className="flex p-2 items-center"><img src={toDo}/>
                <span className=" flex ml-2 text-left ">
                    <span>
                        <b>Tips and Advices:</b> Some example of how this feature can help you:
                        <ul className="list-disc">
                            <li>Make more than one to-do list</li>
                            <li>When you feel lost in a task or need a break from it, look at your to-do list for something small and easy (a less demanding task that does not require high focus) that you can tackle in the meantime.</li>
                            <li>Use specific language and measurable steps to write your tasks.</li>
                        </ul>
                    </span>
                </span>
            </div>
        </>),
        appImage:'testImg',
        icon: <div className="flex items-center justify-center"><img src={toDo} className="justify-center"/> </div>,
        video: null
    },
    {
        title:'Graphical Status Page',
        subtitle:'As we all experience with any Web browser, each page that we load take a good amount of memory from our computer.',
        description: (<>
            <p className="text-left">
            As we all experience with any Web browser, each page that we load take a good amount of memory from our computer.
            </p>
            <p className="text-left">
            Unfortunately, this is inevitable because the website are always more and more heavy and we are all keen to keep more and more tabs open.
            </p>
            <p className="text-left">
            To help you cope with this scenario, BricoBrowser offer you a very practical view of all the resources in use by each workspace and screen.
            </p>
        </>),
        appImage:'testImg',
        icon: <div className="flex items-center justify-center"><img src={monitor} className="justify-center"/> </div>,
        video: null
    },
    {
        title:'Hibernating',
        subtitle:' While visualizing the status of a workspace you can see which one consumes the most',
        description: (<>
            <p className="text-left">
            While visualizing the status of a workspace you can see which one consumes the most, you can activate the sleep function that actually hybernate the status of the workspace and let you save resources till the moment you decide to restore it.
            </p>
        </>),
        appImage:'testImg',
        icon: <div className="flex items-center justify-center"><img src={functionIcon} className="justify-center"/> </div>,
        video: null
    },
    {
        title:'Split Workspace',
        subtitle:'Each workspace has it’s own independent split capability to let you open side by side any resources contained in the workspace.',
        description: (<>
            <p className="text-left">
            Each workspace has it’s own independent split capability to let you open side by side any resources contained in the workspace.
            </p>
        </>),
        appImage:'testImg',
        icon: <div className="flex items-center justify-center"><img src={screen} className="justify-center"/> </div>,
        video: null
    }
]